<template>
  <!--begin::VehicleTypes index-->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("VEHICLE_TYPES.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 pb-0 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("VEHICLE_TYPES.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-6 text-right p-0">
          <button
            class="btn btn-pill button-filter btn-primary mb-3"
            @click="$router.push('/manager/vehicles/types/new')"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("VEHICLE_TYPES.TITLE_ADD_1") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td
            class="font-weight-bolder"
            :title="item.IsBase ? $t('VEHICLE_TYPES.INFO_IS_BASE') : ''"
          >
            <inline-svg
              v-if="item.IsBase"
              class="mr-1"
              src="/media/icons/validation-check-alt-tertiary.svg"
            />
            {{ item.Position + ": " + item.Name }}
          </td>
          <td>
            {{ item.Description }}
          </td>
          <td>
            <span
              class="action-icon"
              @click="
                $router.push(
                  '/manager/vehicles/types/edit/' + item.VehicleTypeID
                )
              "
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span class="action-icon ml-4" @click="deleteVehicleType(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchVehicleTypes(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->

    <!--begin::Back to Models-->
    <div class="row mt-6">
      <button
        class="btn btn-pill btn-white button-form button-form-primary mr-9"
        @click="$router.push('/manager/vehicles')"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
    <!--end::Back to Models-->
  </div>
  <!--end::VehicleTypes index-->
</template>

<script>
import Swal from "sweetalert2";

import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "VehicleTypesIndex",
  data() {
    return {
      TableHeaders: [
        { text: this.$t("VEHICLE_MODELS.TYPE"), value: "Position" },
        { text: this.$t("VEHICLE_TYPES.DESCRIPTION"), value: "Description" },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      SortBy: "Position",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },
  mounted() {
    // Do first search
    this.searchVehicleTypes(false);
  },
  methods: {
    searchVehicleTypes(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      VehicleTypeService.search(
        this.SearchText,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.VehicleTypes;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchVehicleTypes(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchVehicleTypes(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchVehicleTypes(false);
    },

    changeItemsCurrentPage() {
      this.searchVehicleTypes(true);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchVehicleTypes(false);
      }, 600);
    },

    deleteVehicleType(item) {
      Swal.fire({
        title:
          this.$i18n.t("GENERIC.DELETE_ALERT") +
          "\n" +
          item.Position +
          ": " +
          item.Name,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          VehicleTypeService.deleteProfile(item.VehicleTypeID).then(
            statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.searchVehicleTypes(true);
              }
            }
          );
        }
      });
    }
  }
};
</script>
